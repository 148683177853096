<template>
  <div>
    <el-container>
      <el-header>
        <el-row>
          <el-col style="text-align: left;" :span="12">
            尊敬的
            <span style="color:red;font-weight:bold">{{admin_name}}</span>
            ：{{webname}}后台管理系统欢迎您！
          </el-col>
          <el-col style="text-align: right;" :span="12">
            <el-button-group>
              <el-button
                type="text"
                style="color:white;margin-right:20px;"
                icon="el-icon-user"
              >{{admin_name}}</el-button>
              <el-button
                type="text"
                style="color:white;margin-right:20px;"
                icon="el-icon-key"
                @click="chngPswdDiv = true"
              >修改密码</el-button>
              <el-button
                type="text"
                style="color:white;margin-right:20px;"
                @click="logout"
                icon="el-icon-switch-button"
              >退出</el-button>
            </el-button-group>
          </el-col>
        </el-row>
      </el-header>
      <el-container class="maincontent">
        <el-aside style="background:#ffffff" :width="aside_width">
          <el-button size="mini" @click="showmenu">{{menu_act}}</el-button>
          <el-menu :unique-opened="true" :default-active="routepath" router :collapse="isCollapse">
            <el-submenu
              v-for="(item,index) in menulist[0]"
              :index="item.id+''"
              :key="index"
              router
              style="text-align:left"
            >
              <template slot="title">
                <i class="el-icon-menu"></i>
                <span>{{item.title}}</span>
              </template>
              <el-menu-item-group
                v-for="(it,ind) in menulist[item.id]"
                :index="it.id+''"
                :key="ind"
              >
                <el-menu-item :index="it.url" style="padding-left:50px;">{{it.title}}</el-menu-item>
              </el-menu-item-group>
            </el-submenu>
          </el-menu>
        </el-aside>
        <el-main>
          <router-view></router-view>
        </el-main>
      </el-container>
      <el-footer><a href="http://beian.miit.gov.cn">鲁ICP备20002132号-1</a></el-footer>
    </el-container>
    <el-dialog :visible.sync="chngPswdDiv">
      <el-form :model="pswdinfo" ref="pswdinfo" label-width="100px">
        <el-form-item label="原密码" :label-width="formLabelWidth" prop="type">
          <el-input v-model="pswdinfo.oldpassword" autocomplete="off" type="password"></el-input>
        </el-form-item>
        <el-form-item label="新密码" :label-width="formLabelWidth" prop="price">
          <el-input v-model="pswdinfo.newpassword" autocomplete="off" type="password"></el-input>
        </el-form-item>
        <el-form-item label="确认新密码" :label-width="formLabelWidth" prop="price">
          <el-input v-model="pswdinfo.cfrmnewpassword" autocomplete="off" type="password"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="chngPswdDiv = false">取 消</el-button>
        <el-button type="primary" @click="chngpswd">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
export default {
  data() {
    return {
      webname: this.WEBNAME,
      admin_name: sessionStorage.getItem("admin_name"),
      menulist: JSON.parse(sessionStorage.getItem("admin_menulist")),
      isCollapse: false,
      urllist: JSON.parse(sessionStorage.getItem("admin_urllist")),
      chngPswdDiv: false,
      pswdinfo: {
        oldpassword: "",
        newpassword: "",
        cfrmnewpassword: ""
      },
      formLabelWidth:"150px",
    };
  },
  methods: {
    ...mapActions({
      changePswd: "auth/changePassword"
    }),
    chngpswd() {
      if (this.pswdinfo.newpassword != this.pswdinfo.cfrmnewpassword) {
        this.$message({
          type: "error",
          message: "两次输入的新密码不一致"
        });
      } else {
        this.$axios({
          method: "post",
          url: this.HOST + "/admin/index/chngPswd",
          data: {
            info: this.pswdinfo
          }
        }).then(res => {
          if (res.data.status == 1) {
            this.$message({
              type: "info",
              message: "修改成功"
            });
            this.pswdinfo = {
              oldpassword: "",
              newpassword: "",
              cfrmnewpassword: ""
            };
            this.chngPswdDiv = false;
          }
        });
      }
    },
    dialogClose(done) {
      done();
      // this.$confirm('确认关闭？')
      //  .then(_ => {
      //    done();
      //  })
      //  .catch(_ => {});
    },
    logout() {
      sessionStorage.removeItem("admin_id");
      sessionStorage.removeItem("admin_name");
      sessionStorage.removeItem("admin_token");
      sessionStorage.removeItem("admin_login");
      sessionStorage.removeItem("admin_menulist");
      this.$router.push("/login");
    },
    changePassword() {
      this.$message("点击了修改密码");
      this.dialogVisible = true;
    },
    closeDialog() {
      this.$message("点击了关闭");
      this.dialogVisible = false;
    },
    showmenu() {
      this.isCollapse = !this.isCollapse;
    }
  },
  computed: {
    ...mapState({
      navList: state => state.navBar.navList
    }),
    routepath() {
      return this.$route.path;
    },
    menu_act() {
      return this.isCollapse ? "菜单" : "收起菜单";
    },
    aside_width() {
      return this.isCollapse ? "50px" : "200px";
    }
  },
  created() {}
};
</script>

<style>
.flex {
  display: flex;
  justify-content: space-between;
}
</style>
